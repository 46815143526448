import React from 'react';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import appConfig from '../../../../../../../app-config';

const Wrapper = styled.div`
  height: 289px;
  width: ${props => (props.fullWidth ? '100%' : '434px')};
  padding: 20px 24px 0 24px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  background-image: url(${props => props.backgroundImage});
  background-color: ${props => props.backgroundColor};
  display: grid;
  cursor: ${props => (props.layoutStyle === 'offer' ? 'default' : 'pointer')};
  grid-template-rows: 3fr 1fr;
  grid-gap: 10px;
  background-size: contain;

  &:hover {
    box-shadow: 0 2px 7px 0 rgba(66, 59, 112, 0.24);
    transition: all 0.25s ease;
  }
  @media (max-width: 1550px) {
    width: ${props => (props.fullWidth ? '100%' : '314px')};
    height: 209px;
  }

  @media (max-width: 1500px) {
    grid-template-rows: ${props => (props.nextTier ? 'auto auto' : '3fr 1fr')};
  }
`;

const BalanceText = styled.div`
  font-size: 14px;
  color: ${props => props.textColor};
`;

const BalanceSpan = styled.span`
  font-size: 40px;
  font-weight: 900;

  @media (max-width: 1200px) {
    font-size: 250%;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    font-size: 30px;
  }
`;

const ProgressBarBalanceText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.textColor};
`;

const PointsText = styled.span`
  font-size: 40px;
  font-weight: 900;

  @media (max-width: 1200px) {
    font-size: 250%;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    font-size: 30px;
  }
`;

const Progress = styled.progress`
  width: 100%;
  height: 12px;
  border-radius: 8px;

  &::-moz-progress-bar {
    border-radius: 8px;
  }

  &::-webkit-progress-bar {
    border-radius: 8px;
    background-color: ${props => props.progressBarBackgroundColor};
  }

  &::-webkit-progress-value {
    border-radius: 8px;
    background-color: ${props => props.progressBarColor};
  }

  @media (max-width: 1550px) {
    height: 10px;
  }
`;

const ProgressBarWrapper = styled.div`
  // grid-row: 2;
  // grid-column: 1 / span 2;
  // margin: 0 20px;
`;

const LoyaltyLevelDiv = styled.div`
  background-color: ${props => props.backgroundColor};
  color: ${props => (props.nextTier ? '#22362b' : '#fff')};
  border-radius: 14.5px;
  padding: 4px 11px 4px 12px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: auto;
  margin-right: 5px;
  margin-top: 30px;
`;

const DetailsDiv = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
`;

const TierBasedLoyaltyCardVersion3 = ({layoutStyle, loyalty}) => {
  let {loyalty_details, tier_details} = loyalty;
  const balance = loyalty_details.details.current_points_balance;
  const {loyaltyTierDetails} = appConfig;

  return (
    <Wrapper
      fullWidth={layoutStyle === 'offer'}
      onClick={() => navigate('/offers')}
      backgroundImage={loyaltyTierDetails[tier_details.name].backgroundImage}
      backgroundColor={loyaltyTierDetails[tier_details.name].backgroundColor}
      nextTier={tier_details.next_tier_name}
      layoutStyle={layoutStyle}
    >
      <DetailsDiv>
        <div>
          <BalanceText
            id="LoyaltyCard-BalanceDetails"
            textColor={loyaltyTierDetails[tier_details.name].textColor}
          >
            Your Balance:
          </BalanceText>
          <BalanceText
            id="LoyaltyCard-BalanceDetails"
            textColor={loyaltyTierDetails[tier_details.name].textColor}
          >
            <BalanceSpan>{balance} </BalanceSpan>
            <PointsText id="LoyaltyCard-PointsText">
              {balance === 1 ? 'Point' : 'Points'}
            </PointsText>
          </BalanceText>
        </div>

        <LoyaltyLevelDiv
          backgroundColor={loyaltyTierDetails[tier_details.name].textColor}
          nextTier={tier_details.next_tier_name}
        >
          {tier_details.name}
        </LoyaltyLevelDiv>
      </DetailsDiv>
      <div>
        {tier_details.next_tier_name ? (
          <ProgressBarWrapper>
            <ProgressBarBalanceText
              textColor={loyaltyTierDetails[tier_details.name].textColor}
            >
              {tier_details.progress_to_next_tier}% to{' '}
              {tier_details.next_tier_name} Rewards
            </ProgressBarBalanceText>
            <Progress
              max={tier_details.next_tier_points}
              value={balance}
              progressBarColor={
                loyaltyTierDetails[tier_details.name].progressBarColor
              }
              progressBarBackgroundColor={
                loyaltyTierDetails[tier_details.name].progressBarBackgroundColor
              }
            />
          </ProgressBarWrapper>
        ) : (
          <ProgressBarBalanceText
            textColor={loyaltyTierDetails[tier_details.name].textColor}
            style={{marginTop: '15px'}}
          >
            Congrats on reaching Gold Level!
          </ProgressBarBalanceText>
        )}
      </div>
    </Wrapper>
  );
};

export default TierBasedLoyaltyCardVersion3;
