import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import InternalLoyaltyCardBalanceWrapper from '../InternalLoyaltyCardBalanceWrapper';

const LayoutWrapper = styled.div`
  background-color: ${props => props.theme.loyaltyCardBackgroundColour};
`;

const GenericContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto 12px;
  grid-gap: 20px;
  padding: 25px 20px;
  width: 100%;
  height: 100%;
  margin: auto;

  @media (max-width: 1550px) {
    grid-template-columns: 70px auto;
    grid-gap: 15px;
    padding: 15px 12px;
  }
`;

const Progress = styled.progress`
  width: 100%;
  height: 12px;
  border-radius: 5.5px;
  border: 0;
  &::-moz-progress-bar {
    border: 0;
    border-radius: 5.5px;
  }

  &::-webkit-progress-bar {
    border: 0;
    border-radius: 5.5px;
    background-color: ${appConfig.styleConfig.loyaltyBarBackgroundColor};
  }

  &::-webkit-progress-value {
    border: 0;
    border-radius: 5.5px;
    background-color: ${appConfig.styleConfig.loyaltyBarValueColor};
  }

  @media (max-width: 1550px) {
    height: 10px;
  }
`;

const ProgressBarWrapper = styled.div`
  grid-row: 2;
  grid-column: 1 / span 2;
`;

const GenericLogo = styled.img`
  max-height: 96px;
  width: 96px;
  position: relative;

  @media (max-width: 1550px) {
    max-height: 70px;
    width: 70px;
  }
`;

const OfferContentWrapper = styled(GenericContentWrapper)`
  grid-template-columns: 120px auto;
  grid-gap: 15px;
  padding: 15px 12px;

  @media (min-width: 641px) {
    width: 416px;
  }

  @media (max-width: 1550px) {
    grid-template-columns: 120px auto;
    grid-gap: 15px;
    padding: 15px 12px;
  }
`;

const OfferLogo = styled.img`
  max-height: 120px;
  width: 120px;
`;

const InternalLoyaltyCardBodyB = props => {
  let ContentWrapper = GenericContentWrapper;
  let Logo = GenericLogo;

  if ('offer' === props.layoutStyle) {
    ContentWrapper = OfferContentWrapper;
    Logo = OfferLogo;
  }

  return (
    <LayoutWrapper onClick={() => navigate('/offers')}>
      <ContentWrapper mediaQueryMaxWidth={props.mediaQueryMaxWidth}>
        <div style={{margin: 'auto'}}>
          <Logo src={appConfig.styleConfig.loyaltyCardImage} />
        </div>
        <ProgressBarWrapper>
          <Progress max="100" value={props.nextOfferProgressValue} />
        </ProgressBarWrapper>
        <InternalLoyaltyCardBalanceWrapper {...props} />
      </ContentWrapper>
    </LayoutWrapper>
  );
};

export default InternalLoyaltyCardBodyB;
