import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import InternalLoyaltyCardBalanceWrapper from '../InternalLoyaltyCardBalanceWrapper';
import {Body, Headline, Title1} from '../../../../../components/Typography';

const LayoutWrapper = styled.div`
  background-color: #fff;

  padding: 22px 14px;

  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  display: grid;
  grid-gap: 15px;

  height: ${props => (props.layoutStyle === 'offer' ? '293px' : '209px')};
  width: ${props => (props.layoutStyle === 'offer' ? '100%' : '314px')};

  @media (min-width: 1551px) {
    height: ${props => (props.layoutStyle === 'offer' ? '293px' : '289px')};
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '434px')};
  }

  @media (max-width: 360px) {
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '280px')};
  }
`;

const BalanceAndLogoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;

  margin: 0 9.5px;
`;

const BalanceWrapper = styled.div`
  display: grid;
  grid-gap: 4px;
`;

const Balance = styled(Title1)`
  color: #000;
  font-weight: 600;
`;

const NextRewardWrapper = styled.div`
  border-radius: 9px;
  padding: 15px 10px;

  display: grid;
  grid-gap: 10px;
  min-height: 100px;
  background: rgba(208, 175, 79, 0.35);
`;

const NextRewardOpacity = styled(NextRewardWrapper)``;

const NextRewardOfferWrapper = styled.div`
  font-color: #000;
  // display: grid;
  // grid-gap: 5px;
`;

const NextOffer = styled(Headline)`
  color: #000;
  // margin-top: 5px;
`;

const Progress = styled.progress`
  width: 100%;
  height: 12px;
  border-radius: 0;
  border: 0;

  &::-moz-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
  }

  &::-webkit-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
    background-color: #fff;
  }

  &::-webkit-progress-value {
    border: 0;
    border-top-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-bottom-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-top-right-radius: 5.5px;
    border-bottom-right-radius: 5.5px;
    background-color: #d0af4f;
  }

  @media (max-width: 1550px) {
    height: 10px;
  }
`;

const DelyseesLoyaltyCard = props => {
  return (
    <LayoutWrapper
      layoutStyle={props.layoutStyle}
      onClick={() => navigate('/offers')}
    >
      <BalanceAndLogoWrapper>
        <BalanceWrapper>
          <Body style={{color: '#000', fontWeight: 600}}>Balance:</Body>
          <Balance>
            {props.balance} {props.balance === 1 ? 'point' : 'points'}
          </Balance>
        </BalanceWrapper>
        <div>
          <img
            src="https://s3.amazonaws.com/assets.smoothpay.com/web/delysees-assets/splatter%403x.png"
            width="250px"
            height="180px"
            style={{position: 'absolute'}}
          />
        </div>
      </BalanceAndLogoWrapper>
      <NextRewardWrapper>
        {props.nextOfferTitle ? (
          <NextRewardOfferWrapper>
            <Body style={{color: '#000', fontWeight: 800}}>Next Reward:</Body>
            <NextOffer>{props.nextOfferTitle}</NextOffer>
          </NextRewardOfferWrapper>
        ) : (
          <NextOffer>All Rewards Unlocked</NextOffer>
        )}
        <Progress
          max="100"
          value={props.nextOfferProgressValue}
          // layoutStyle={layoutStyle}
        />
      </NextRewardWrapper>
    </LayoutWrapper>
  );
};

export default DelyseesLoyaltyCard;
