import SemiCircleProgressBar from 'react-progressbar-semicircle';
import React, {Fragment} from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import {
  Headline,
  Caption1,
  Callout,
} from '../../../../../components/Typography';
import {loyaltyCardLayoutInfoObj} from '../../utils/loyaltyCardLayoutHelper';

const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: ${props => props.gridRowLayout};
  grid-template-columns: ${props =>
    props.layoutStyle == 'menu'
      ? props.gridColumnMenuLayout
      : props.gridColumnLayout};
  grid-gap: ${props =>
    props.layoutStyle == 'menu' ? props.gridGapMenu : props.gridGap};
  background-image: url(${props => props.backgroundImage});
  background-color: ${props => props.background};
  height: ${props => props.height};

  @media (max-width: 786px) {
    grid-template-columns: ${props => props.gridColumnMobileLayout};
  }
`;

const ProgressBarDiv = styled.div`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  margin: ${props =>
    props.layoutStyle == 'menu' ? props.marginMenu : props.margin};
  z-index: 1;
`;

const ImageDiv = styled.div`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  align-self: ${props => props.alignSelf};
  margin: ${props => props.margin};
`;

const NextDataDiv = styled.div`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  margin-left: ${props =>
    props.layoutStyle == 'menu' ? props.marginLeft : '0'};
`;

const BalanceDiv = styled.div`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  display: ${props => (props.display ? props.display : 'block')};
  margin: ${props => props.margin};
  /*margin-left: ${props =>
    props.layoutStyle == 'menu' ? props.marginLeft : '0'};*/
`;

const Footer = styled.div`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  background: ${props => props.background};
  background-color: ${props =>
    !props.background ? props.backgroundColor : null};
  border-radius: ${props => props.borderRadius};
`;

const BalanceText = styled(Headline)`
  color: ${props => props.textColor};
  text-align: ${props => props.textAlign};
  margin-top: ${props =>
    props.layoutStyle == 'menu' ? props.marginTopForMenu : props.marginTop};
  font-size: ${props =>
    props.layoutStyle == 'menu' ? props.fontSizeForMenu : props.fontSize};
`;

const PointsDiv = styled(Headline)`
  font-size: ${props =>
    props.layoutStyle == 'menu' ? props.fontSizeForMenu : props.fontSize};
  text-align: ${props => props.textAlign};
`;

const BalanceSpan = styled.span``;

const PointsText = styled.span``;
const Progress = styled.progress`
  width: 100%;
  height: 12px;
  border-radius: 0;
  border: 0;

  &::-moz-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
  }

  &::-webkit-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
    background-color: ${appConfig.styleConfig.loyaltyBarBackgroundColor};
  }

  &::-webkit-progress-value {
    border: 0;
    border-top-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-bottom-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-top-right-radius: 5.5px;
    border-bottom-right-radius: 5.5px;
    background-color: ${appConfig.styleConfig.loyaltyBarValueColor};
  }

  @media (max-width: 1550px) {
    height: 10px;
  }
`;

const PercentWrapper = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin: 52px auto auto auto;
  text-align: center;
`;

const Percent = styled(Headline)`
  font-size: 36px;
`;

const GenericLoyaltyCardBody = ({
  layoutStyle,
  nextOfferProgress,
  nextOfferTitle,
  balance,
  loyalty,
  nextOfferProgressValue
}) => {
  const loyaltyCardLayoutInfo =
    loyaltyCardLayoutInfoObj[appConfig.loyaltyBarStyle];

  const nextProgress = loyalty.loyalty_details.details.next_offer
    ? loyalty.loyalty_details.details.next_offer.data.offer_details
        .points_cost - balance
    : 0;

  return (
    <ContentWrapper
      onClick={() => navigate('/offers')}
      {...loyaltyCardLayoutInfo.contentWrapper}
      layoutStyle={layoutStyle}
      backgroundImage={
        appConfig.styleConfig.loyaltyCardBackgroundImage
          ? appConfig.styleConfig.loyaltyCardBackgroundImage
          : ''
      }
      background={
        appConfig.styleConfig.loyaltyCardBackgroundColour
          ? appConfig.styleConfig.loyaltyCardBackgroundColour
          : ''
      }
    >
      <ProgressBarDiv
        layoutStyle={layoutStyle}
        {...loyaltyCardLayoutInfo.progressBarDiv}
      >
        {appConfig.loyaltyBarStyle == 'semicircle' && (
          <SemiCircleProgressBar
            percentage={nextOfferProgressValue}
            stroke={appConfig.styleConfig.loyaltyBarValueColor}
          />
        )}
        {appConfig.loyaltyBarStyle == 'horizontal' && (
          <Progress
            max="100"
            value={nextOfferProgressValue}
            layoutStyle={layoutStyle}
          />
        )}
      </ProgressBarDiv>

      {appConfig.styleConfig.loyaltyCardImage ? (
        <ImageDiv {...loyaltyCardLayoutInfo.imageDiv}>
          <img
            src={appConfig.styleConfig.loyaltyCardImage}
            width="auto"
            style={{...loyaltyCardLayoutInfo.image}}
          />
        </ImageDiv>
      ) : appConfig.loyaltyCardBarShowPercent ? (
        <PercentWrapper>
          <Percent>{nextOfferProgressValue}%</Percent>
          {nextOfferProgress !== null ? <Callout>to your next reward</Callout> : null}
        </PercentWrapper>
      ) : null}
      <NextDataDiv
        layoutStyle={layoutStyle}
        {...loyaltyCardLayoutInfo.nextDataDiv}
      >
        {nextOfferTitle != '' ? (
          <Fragment>
            <Caption1 style={{...loyaltyCardLayoutInfo.caption1}}>
              {appConfig.loyaltyBarStyle != 'semicircle'
                ? `${nextProgress} Points to Your Next Reward:`
                : appConfig.loyaltyCardBarShowPercent
                ? ''
                : 'Your Next Reward:'}
            </Caption1>
            <Headline
              style={{...loyaltyCardLayoutInfo.headline}}
              id="LoyaltyCard-OfferHead"
            >
              {nextOfferTitle}
            </Headline>
          </Fragment>
        ) : (
          <Headline
            id="LoyaltyCard-CardInfoHeadline"
            style={{...loyaltyCardLayoutInfo.headline}}
            color={appConfig.styleConfig.loyaltyCardTextColour}
          >
            All rewards unlocked!
          </Headline>
        )}
      </NextDataDiv>

      <Footer
        {...loyaltyCardLayoutInfo.footer}
        background={props => props.theme.loyaltyCardFooterBackgroundColour}
      ></Footer>

      <BalanceDiv
        id="LoyaltyCard-BalanceDiv"
        layoutStyle={layoutStyle}
        {...loyaltyCardLayoutInfo.balanceDiv}
      >
        <BalanceText
          id="LoyaltyCard-BalanceText"
          {...loyaltyCardLayoutInfo.balanceText}
          layoutStyle={layoutStyle}
        >
          {loyaltyCardLayoutInfo.balanceText.string}
        </BalanceText>
        <PointsDiv
          {...loyaltyCardLayoutInfo.pointsDiv}
          style={{...loyaltyCardLayoutInfo.pointsDiv.style}}
          layoutStyle={layoutStyle}
        >
          <BalanceSpan id="LoyaltyCard-BalanceSpan">
            {layoutStyle == 'menu' && balance.toString().length > 5
              ? balance.toString().slice(0, 5) + '...'
              : balance}
          </BalanceSpan>
          {((layoutStyle == 'menu' && balance.toString().length <= 5) ||
            layoutStyle == 'offer') && (
            <PointsText id="LoyaltyCard-PointsText">
              {balance === 1 || balance == 0 ? ' Point' : ' Points'}
            </PointsText>
          )}
        </PointsDiv>
      </BalanceDiv>
    </ContentWrapper>
  );
};

export default GenericLoyaltyCardBody;
