import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import InternalLoyaltyCardBalanceWrapper from '../InternalLoyaltyCardBalanceWrapper';
import {
  Body,
  Headline,
  Title1,
  Title2,
  Title3,
} from '../../../../../components/Typography';

const loyaltyTheme = appConfig.loyaltyTheme || {
  contentBackground: '',
  brandLogo: '',
  rewardText: {singular: '', plural: ''},
  rewardTextColor: '',
  nextOfferColor: '',
  footerBackground: '',
  yourBalanceText: '',
  yourBalanceColor: '',
  balanceText: {singular: '', plural: ''},
  balanceColor: '',
  allRewardsUnlockedTitleText: '',
  allRewardsUnlockedContentText: '',
  progressUnfillColor: '',
  progressFillColor: '',
  progressDotUncheckedColor: '',
  maxPointsBalance: 2000,
  pointsInOneInterval: 50,
};

const LayoutWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  background: ${loyaltyTheme.contentBackground};
  background-size: cover;
  padding: 52px 41px 41px 41px;
  grid-gap: 20px;

  height: ${props => (props.layoutStyle === 'offer' ? '293px' : '209px')};
  width: ${props => (props.layoutStyle === 'offer' ? '100%' : '314px')};

  @media (min-width: 1551px) {
    height: ${props => (props.layoutStyle === 'offer' ? '293px' : '289px')};
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '434px')};
  }

  @media (max-width: 1550px) {
    padding: 34px 28px 24px 28px;
  }

  @media (max-width: 360px) {
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '280px')};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RewardWrapper = styled.div`
  display: grid;
  grid-gap: 7px;
  align-self: end;
`;

const RewardTitleText = styled(Body)`
  color: ${loyaltyTheme.rewardTextColor};
  font-size: 19px;
`;

const NextOfferText = styled(Title3)`
  color: ${loyaltyTheme.nextOfferColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 19px;
`;

const BalanceWrapper = styled.div`
  display: grid;
  grid-gap: 5px;
  align-items: center;
`;

const YourBalance = styled(Body)`
  color: ${loyaltyTheme.yourBalanceColor};
`;

const Balance = styled(Title1)`
  color: ${loyaltyTheme.balanceColor};
  font-weight: 600;
`;

const ProgressBarWrapper = styled.ol`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  margin: 0;

  @media (max-width: 1550px) {
    margin: ${props => (props.layoutStyle == 'menu' ? '-10px 0 10px 0' : '0')};
  }
`;

const ProgressBarSection = styled.li`
  flex: 2;
  position: relative;
  padding: 0 0 14px 0;
  color: #ffffff;
  white-space: nowrap;
  overflow: visible;
  min-width: 0;
  text-align: center;
  border-bottom: 5px solid ${loyaltyTheme.progressUnfillColor};

  span {
    margin-left: -8px;
  }

  &:first-child {
    flex: 1;
    text-align: left;

    span {
      margin-left: ${props => (props.layoutStyle == 'menu' ? '-5px' : '5px')};
    }
  }

  &:last-child {
    flex: 1;
    text-align: right;

    span {
      margin-left: ${props =>
        props.layoutStyle == 'menu' ? '-15px' : '-20px'};
    }
  }

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${props =>
      props.complete
        ? `${loyaltyTheme.progressFillColor}`
        : `${loyaltyTheme.progressDotUncheckedColor}`};
    border-radius: 50%;
    border: 3px solid
      ${props =>
        props.complete
          ? `${loyaltyTheme.progressFillColor}`
          : `${loyaltyTheme.progressUnfillColor}`};
    position: absolute;
    left: calc(50% - 6px);
    bottom: -11px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
  }

  &:first-child:before {
    left: 0;
    margin-left: -1px;
  }

  &:last-child:before {
    right: 0;
    left: auto;
    margin-right: -1px;
  }

  &:first-child:after {
    content: '';
    display: block;
    width: ${props =>
      props.active && props.intervalInPercents
        ? `${props.intervalInPercents}%`
        : ''};
    position: absolute;
    bottom: -5px;
    z-index: 1;
    border-bottom: 5px solid ${loyaltyTheme.progressFillColor};
    left: ${props =>
      props.secondProgressBar || props.thirdProgressBar ? '-200%' : '0'};
  }

  &:last-child:after {
    content: '';
    display: block;
    width: ${props => (props.thirdProgressBar ? '0' : '200%')};
    position: absolute;
    bottom: -5px;
    left: 100%;
    z-index: 0;
    border-bottom: 5px solid ${loyaltyTheme.progressUnfillColor};
  }
`;

const ProgressBarText = styled.span`
  position: absolute;
  margin-top: 30px;
  font-size: ${props => (props.layoutStyle == 'menu' ? '12px' : '14px')};

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const StyleFourteen = props => {
  const maxPointsBalance = loyaltyTheme.maxPointsBalance;
  const pointsInOneInterval = loyaltyTheme.pointsInOneInterval;
  const defaultIntervalAmount = 6;
  const intervalsArray = [];

  const amountOfCards = Math.ceil(
    maxPointsBalance / (defaultIntervalAmount * pointsInOneInterval)
  );

  for (let i = 1; i < amountOfCards; i++) {
    intervalsArray.push(pointsInOneInterval * i * defaultIntervalAmount);
  }
  intervalsArray.push(maxPointsBalance);

  const arrayIndex = intervalsArray.findIndex((e, i) => props.balance <= e);

  return (
    <LayoutWrapper
      layoutStyle={props.layoutStyle}
      onClick={() => navigate('/offers')}
    >
      <HeaderWrapper>
        <BalanceWrapper>
          <YourBalance>{loyaltyTheme.yourBalanceText}</YourBalance>
          <Balance>{`${props.balance} ${
            props.balance === 1
              ? loyaltyTheme.balanceText.singular
              : loyaltyTheme.balanceText.plural
          }`}</Balance>
        </BalanceWrapper>
        <img
          src={loyaltyTheme.brandLogo}
          width="auto"
          style={{maxHeight: '71px'}}
        />
      </HeaderWrapper>
      {arrayIndex === 0 && (
        <ProgressBarWrapper layoutStyle={props.layoutStyle}>
          <ProgressBarSection
            active
            complete
            intervalInPercents={(props.balance * 200) / pointsInOneInterval}
            layoutStyle={props.layoutStyle}
          >
            <ProgressBarText layoutStyle={props.layoutStyle}>0</ProgressBarText>
          </ProgressBarSection>
          {[...Array(defaultIntervalAmount)].map((value, index) => (
            <ProgressBarSection
              layoutStyle={props.layoutStyle}
              complete={
                (index + 1) * pointsInOneInterval <= props.balance
                  ? true
                  : false
              }
            >
              <ProgressBarText layoutStyle={props.layoutStyle}>
                {(index + 1) * pointsInOneInterval}
              </ProgressBarText>
            </ProgressBarSection>
          ))}
        </ProgressBarWrapper>
      )}

      {arrayIndex > 0 && arrayIndex < amountOfCards - 1 && (
        <ProgressBarWrapper layoutStyle={props.layoutStyle}>
          <ProgressBarSection
            active
            complete
            intervalInPercents={
              ((props.balance - intervalsArray[arrayIndex - 1]) * 200) /
                pointsInOneInterval +
              200
            }
            secondProgressBar
            layoutStyle={props.layoutStyle}
          >
            <ProgressBarText layoutStyle={props.layoutStyle}>
              {intervalsArray[arrayIndex - 1]}
            </ProgressBarText>
          </ProgressBarSection>
          {[...Array(defaultIntervalAmount)].map((value, index) => (
            <ProgressBarSection
              complete={
                (index + 1) * pointsInOneInterval <=
                props.balance - intervalsArray[arrayIndex - 1]
                  ? true
                  : false
              }
              layoutStyle={props.layoutStyle}
            >
              <ProgressBarText layoutStyle={props.layoutStyle}>
                {intervalsArray[arrayIndex - 1] +
                  (index + 1) * pointsInOneInterval}
              </ProgressBarText>
            </ProgressBarSection>
          ))}
        </ProgressBarWrapper>
      )}

      {arrayIndex === amountOfCards - 1 && (
        <ProgressBarWrapper layoutStyle={props.layoutStyle}>
          <ProgressBarSection
            active
            complete
            intervalInPercents={
              ((props.balance -
                (intervalsArray[arrayIndex] -
                  pointsInOneInterval * defaultIntervalAmount)) *
                200) /
                pointsInOneInterval +
              200
            }
            thirdProgressBar
            layoutStyle={props.layoutStyle}
          >
            <ProgressBarText layoutStyle={props.layoutStyle}>
              {intervalsArray[arrayIndex] -
                pointsInOneInterval * defaultIntervalAmount}
            </ProgressBarText>
          </ProgressBarSection>
          {[...Array(defaultIntervalAmount)].map((value, index) => (
            <ProgressBarSection
              complete={
                (index + 1) * pointsInOneInterval <=
                props.balance -
                  (intervalsArray[arrayIndex] -
                    pointsInOneInterval * defaultIntervalAmount)
                  ? true
                  : false
              }
              layoutStyle={props.layoutStyle}
              thirdProgressBar
            >
              <ProgressBarText layoutStyle={props.layoutStyle}>
                {intervalsArray[arrayIndex] -
                  pointsInOneInterval * defaultIntervalAmount +
                  (index + 1) * pointsInOneInterval}
              </ProgressBarText>
            </ProgressBarSection>
          ))}
        </ProgressBarWrapper>
      )}
      <RewardWrapper>
        {props.nextOfferTitle ? (
          <>
            <NextOfferText>{`0 ${
              //replace props.balance with number of orders till next reward
              props.balance == 1
                ? loyaltyTheme.rewardText.singular
                : loyaltyTheme.rewardText.plural
            }`}</NextOfferText>
          </>
        ) : (
          <>
            <RewardTitleText>
              {loyaltyTheme.allRewardsUnlockedTitleText}
            </RewardTitleText>
          </>
        )}
      </RewardWrapper>
    </LayoutWrapper>
  );
};

export default StyleFourteen;
