import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import InternalLoyaltyCardBalanceWrapper from '../InternalLoyaltyCardBalanceWrapper';
import {
  Body,
  Headline,
  Title1,
  Title2,
} from '../../../../../components/Typography';

const LayoutWrapper = styled.div`
  background: #000000;
  background-size: cover;

  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);

  height: ${props => (props.layoutStyle === 'offer' ? '293px' : '209px')};
  width: ${props => (props.layoutStyle === 'offer' ? '100%' : '314px')};

  @media (min-width: 1551px) {
    height: ${props => (props.layoutStyle === 'offer' ? '293px' : '289px')};
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '434px')};
  }

  @media (max-width: 360px) {
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '280px')};
  }
`;

const ContentWrapper = styled.div`
  height: 226px;
  position: relative;
  padding: 28px 20px;
  display: grid;
  grid-gap: 15px;
  grid-template-rows: 1fr auto;

  @media (max-width: 1550px) {
    height: ${props => (props.layoutStyle === 'offer' ? '226px' : 'unset')};
  }
`;

const LogoContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  align-items: center;
`;

const RewardsWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const RewardWrapper = styled.div`
  display: grid;
`;

const YourBalance = styled(Body)`
  color: #fff;
`;

const Balance = styled(Title2)`
  color: #fff;
`;

const NextReward = styled(Body)`
  color: #fff;
`;

const NextOffer = styled(Headline)`
  color: #fff;
`;

const Logo = styled.img`
  height: 100px;
  width: auto;

  @media (max-width: 1550px) {
    height: ${props => (props.layoutStyle === 'offer' ? '100px' : '50px')};
  }

  @media (max-width: 360px) {
    display: ${props => (props.layoutStyle === 'offer' ? 'block' : 'none')};
  }
`;

const Footer = styled.div`
  background: url('https://s3.amazonaws.com/assets.smoothpay.com/web/shiny-tea/bar-image%403x.png');
  background-size: cover;
  height: ${props => (props.layoutStyle === 'offer' ? '67px' : '63px')};
`;

const Progress = styled.progress`
  width: 100%;
  height: 12px;
  border-radius: 0;
  border: 0;

  &::-moz-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
  }

  &::-webkit-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
    background-color: #fff;
  }

  &::-webkit-progress-value {
    border: 0;
    border-top-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-bottom-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-top-right-radius: 5.5px;
    border-bottom-right-radius: 5.5px;
    background-color: #e62000;
  }

  @media (max-width: 1550px) {
    height: 10px;
  }
`;

const ShinyTeaLoyaltyCard = props => {
  return (
    <LayoutWrapper
      layoutStyle={props.layoutStyle}
      onClick={() => navigate('/offers')}
    >
      <ContentWrapper layoutStyle={props.layoutStyle}>
        <LogoContentWrapper>
          <Logo
            layoutStyle={props.layoutStyle}
            src="https://s3.amazonaws.com/assets.smoothpay.com/web/shiny-tea/logo-shiny-tea%403x.png"
          />
          <RewardsWrapper>
            <RewardWrapper>
              <YourBalance>Your Balance:</YourBalance>
              <Balance>
                {props.balance} {props.balance === 1 ? 'point' : 'points'}
              </Balance>
            </RewardWrapper>
            {props.nextOfferTitle ? (
              <RewardWrapper>
                <NextReward>Next Reward:</NextReward>
                <NextOffer>{props.nextOfferTitle}</NextOffer>
              </RewardWrapper>
            ) : (
              <NextOffer>All Rewards Unlocked</NextOffer>
            )}
          </RewardsWrapper>
        </LogoContentWrapper>
        <Progress
          max="100"
          value={props.nextOfferProgressValue}
          // layoutStyle={layoutStyle}
        />
      </ContentWrapper>
      <Footer layoutStyle={props.layoutStyle} />
    </LayoutWrapper>
  );
};

export default ShinyTeaLoyaltyCard;
