import React from 'react';
import styled from 'styled-components';
import {Headline, Caption1, Subhead} from '../../../../components/Typography';
import appConfig from '../../../../../app-config';

const BalanceWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 15px;
  margin: auto 0;
`;

const InternalLoyaltyCardBalanceWrapper = ({
  balance,
  nextOfferProgress,
  nextOfferTitle,
  layoutStyle,
}) => {
  return (
    <BalanceWrapper>
      <div>
        <Caption1
          style={{fontWeight: '600'}}
          color={appConfig.styleConfig.loyaltyCardTextColour}
        >
          Your Balance:
        </Caption1>
        <Headline
          color={appConfig.styleConfig.loyaltyCardTextColour}
          id="LoyaltyCard-PointsHeadline"
        >
          {balance} Points
        </Headline>
      </div>
      {nextOfferProgress !== null ? (
      <div>
        <Caption1
          style={{fontWeight: '600'}}
          color={appConfig.styleConfig.loyaltyCardTextColour}
        >
          {nextOfferProgress}% to Your Next Reward:
        </Caption1>
        <Subhead
          style={{color: appConfig.styleConfig.loyaltyCardTextColour}}
          id="LoyaltyCard-PointsSubhead"
        >
          {layoutStyle === 'offer'
            ? nextOfferTitle
            : nextOfferTitle.substring(0, 15) + '...'}
        </Subhead>
      </div>
      ) : (
      <div>
        <Caption1
          style={{fontWeight: '600'}}
          color={appConfig.styleConfig.loyaltyCardTextColour}
        >
          All rewards unlocked!
        </Caption1>

      </div>
      )}
    </BalanceWrapper>
  );
};

export default InternalLoyaltyCardBalanceWrapper;
