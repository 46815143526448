import React from "react";
import styled from "styled-components";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { navigate } from "gatsby";
import appConfig from "../../../../../../app-config";
import { Body, Title1, Title2 } from "../../../../../components/Typography";

const loyaltyTheme = appConfig.loyaltyTheme || {
  contentBackground: "",
  rewardText: "",
  rewardTextColor: "",
  nextOfferColor: "",
  footerBackground: "",
  yourBalanceText: "",
  yourBalanceColor: "",
  balanceText: { singular: "", plural: "" },
  balanceColor: "",
  allRewardsUnlockedTitleText: "",
  allRewardsUnlockedContentText: "",
  progressUnfillColor: "",
  progressFillColor: "",
};

const LayoutWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  display: grid;
  grid-template-rows: 2fr 1fr;

  height: ${(props) => (props.layoutStyle === "offer" ? "293px" : "209px")};
  width: ${(props) => (props.layoutStyle === "offer" ? "100%" : "314px")};

  @media (min-width: 1551px) {
    height: ${(props) => (props.layoutStyle === "offer" ? "293px" : "289px")};
    width: ${(props) => (props.layoutStyle === "offer" ? "100%" : "434px")};
  }

  @media (max-width: 360px) {
    width: ${(props) => (props.layoutStyle === "offer" ? "100%" : "280px")};
  }
`;

const ContentWrapper = styled.div`
  background-color: ${loyaltyTheme.contentBackground};
  background-image: url(${loyaltyTheme.contentBackgroundImage});
  background-size: cover;
  padding: 52px 41px 41px 41px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 33px;
  text-align: center;
  align-items: center;

  @media (max-width: 1550px) {
    padding: ${(props) =>
      props.layoutStyle === "offer"
        ? "20px 20px 20px 20px"
        : "5px 20px 5px 20px"};
    grid-gap: 20px;
  }
`;

const ProgressBar = styled.div`
  margin: ${(props) =>
    props.layoutStyle == "menu" ? props.marginMenu : props.margin};
  z-index: 1;
  grid-area: 1/2;
`;

const BalanceDiv = styled.div`
  grid-area: 1/2;
  align-self: center;
  margin-top: 60px;
`;

const YourBalance = styled(Body)`
  color: ${loyaltyTheme.yourBalanceColor};
`;

const Balance = styled(Title1)`
  color: ${loyaltyTheme.balanceColor};
`;

const RewardWrapper = styled.div`
  display: grid;
  grid-gap: 7px;
`;

const RewardTitleText = styled(Body)`
  color: ${loyaltyTheme.rewardTextColor};
`;

const NextOfferText = styled(Title2)`
  color: ${loyaltyTheme.nextOfferColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FooterWrapper = styled.div`
  background: ${loyaltyTheme.footerBackground};
  padding: 18px 41px;
  display: grid;
  grid-gap: 5px;
  text-align: center;
  align-items: center;

  @media (max-width: 1550px) {
    padding: 15px 20px;
  }
`;

const smth = styled(SemiCircleProgressBar)``;

const StyleTwo = (props) => {
  return (
    <LayoutWrapper
      layoutStyle={props.layoutStyle}
      onClick={() => navigate("/offers")}
    >
      <ContentWrapper>
        <ProgressBar layoutStyle={props.layoutStyle}>
          <SemiCircleProgressBar
            percentage={props.nextOfferProgressValue}
            stroke={loyaltyTheme.progressFillColor}
            background={loyaltyTheme.progressUnfillColor}
            diameter={220}
          />
        </ProgressBar>
        <BalanceDiv>
          <YourBalance>{loyaltyTheme.yourBalanceText}</YourBalance>
          <Balance>{`${props.balance} ${
            props.balance === 1
              ? loyaltyTheme.balanceText.singular
              : loyaltyTheme.balanceText.plural
          }`}</Balance>
        </BalanceDiv>
      </ContentWrapper>
      <FooterWrapper>
        <RewardWrapper>
          {props.nextOfferTitle ? (
            <>
              <RewardTitleText>{loyaltyTheme.rewardText}</RewardTitleText>
              <NextOfferText>{props.nextOfferTitle}</NextOfferText>{" "}
            </>
          ) : (
            <>
              <RewardTitleText>
                {loyaltyTheme.allRewardsUnlockedTitleText}
              </RewardTitleText>
              <NextOfferText>
                {loyaltyTheme.allRewardsUnlockedContentText}
              </NextOfferText>
            </>
          )}
        </RewardWrapper>
      </FooterWrapper>
    </LayoutWrapper>
  );
};

export default StyleTwo;
