import React from 'react';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import appConfig from '../../../../../../../app-config';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Wrapper = styled.div`
  height: 289px;
  width: ${props => (props.fullWidth ? '100%' : '434px')};
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  background-image: ${props =>
    props.fullWidth
      ? `-webkit-linear-gradient(
    163deg,
    #f2f2f2 30%,
    ${props.backgroundColor} 30%`
      : `-webkit-linear-gradient(
    163deg,
    #f2f2f2 40%,
    ${props.backgroundColor} 35%`});
  display: grid;
  cursor: ${props => (props.layoutStyle === 'offer' ? 'default' : 'pointer')};
  grid-template-columns: ${props =>
    props.fullWidth ? '1fr 250px' : '1fr 150px'};
  grid-gap: 10px;

  &:hover {
    box-shadow: 0 2px 7px 0 rgba(66, 59, 112, 0.24);
    transition: all 0.25s ease;
  }
  @media (max-width: 1550px) {
    width: ${props => (props.fullWidth ? '100%' : '314px')};
    height: 209px;
    grid-template-columns: ${props =>
      props.fullWidth ? '1fr 130px' : '1fr 105px'};
  }

  @media(max-width: 768px){
    background-image: -webkit-linear-gradient(
      163deg,
      #f2f2f2 40%,
      ${props => props.backgroundColor} 35%);
  }
`;

const CircularProgressbarWithChildrenWrapper = styled.div`
  align-self: center;
  text-align: center;

  @media (max-width: 1550px) {
    max-width: 100px;
  }
`;

const ContentWrapper = styled.div`
  align-self: center;
  padding: 10px;
`;

const BalanceTextDiv = styled.div`
  color: ${props => props.textColor};
  width: max-content;
  margin-top: 12px;
  font-size: 16.8px;
  text-transform: uppercase;
  line-height: 1.36;
  letter-spacing: 0.03px;
  padding: 3px;
  max-width: ${props => (props.fullWidth ? '100%' : '170px')};

  @media (max-width: 768px) {
    max-width: 165px;
  }
`;

const TierText = styled.p`
  font-size: 24px;
  color: ${props => props.textColor};
  // color: #58595b;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.03px;
  text-transform: uppercase;
  font-style: italic;
`;

const TierBasedLoyaltyCardVersion2 = ({layoutStyle, loyalty}) => {
  let {loyalty_details, tier_details} = loyalty;
  const balance = loyalty_details.details.current_points_balance;
  const {loyaltyTierDetails} = appConfig;

  return (
    <Wrapper
      fullWidth={layoutStyle === 'offer'}
      onClick={() => navigate('/offers')}
      backgroundImage={loyaltyTierDetails[tier_details.name].backgroundImage}
      backgroundColor={loyaltyTierDetails[tier_details.name].backgroundColor}
      layoutStyle={layoutStyle}
    >
      <ContentWrapper>
        <TierText
          id="LoyaltyCard-TierText"
          textColor={loyaltyTierDetails[tier_details.name].textColor}
        >{`${tier_details.name} Status`}</TierText>
        {tier_details.next_tier_name ? (
          <BalanceTextDiv
            id="LoyaltyCard-BalanceTextDiv"
            textColor={loyaltyTierDetails[tier_details.name].textColor}
            fullWidth={layoutStyle === 'offer'}
          >
            ${tier_details.next_tier_points - balance} to{' '}
            {tier_details.next_tier_name} Status
          </BalanceTextDiv>
        ) : (
          <BalanceTextDiv
            textColor={loyaltyTierDetails[tier_details.name].textColor}
          >
            You have arrived
          </BalanceTextDiv>
        )}
      </ContentWrapper>

      <CircularProgressbarWithChildrenWrapper>
        {tier_details.next_tier_name && (
          <CircularProgressbarWithChildren
            value={
              tier_details.next_tier_name
                ? (balance / tier_details.next_tier_points) * 100
                : balance
            }
            styles={{
              root: {
                maxWidth: '120px',
              },
              path: {
                stroke: loyaltyTierDetails[tier_details.name].progressBarColor,
                // transform: 'rotate(50deg)',
                transformOrigin: 'center center',
              },
              trail: {
                // transform: 'rotate(50deg)',
                transformOrigin: 'center center',
              },
            }}
          />
        )}
      </CircularProgressbarWithChildrenWrapper>
    </Wrapper>
  );
};

export default TierBasedLoyaltyCardVersion2;
