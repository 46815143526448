import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import InternalLoyaltyCardBalanceWrapper from '../InternalLoyaltyCardBalanceWrapper';
import {
  Body,
  Headline,
  Title1,
  Title2,
} from '../../../../../components/Typography';

const loyaltyTheme = appConfig.loyaltyTheme || {
  contentBackground: '',
  rewardText: '',
  rewardTextColor: '',
  nextOfferColor: '',
  footerBackground: '',
  yourBalanceText: '',
  yourBalanceColor: '',
  balanceText: {singular: '', plural: ''},
  balanceColor: '',
  allRewardsUnlockedTitleText: '',
  allRewardsUnlockedContentText: '',
  progressUnfillColor: '',
  progressFillColor: '',
};

const LayoutWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  display: grid;
  grid-template-rows: 2fr 1fr;

  height: ${props => (props.layoutStyle === 'offer' ? '293px' : '209px')};
  width: ${props => (props.layoutStyle === 'offer' ? '100%' : '314px')};

  @media (min-width: 1551px) {
    height: ${props => (props.layoutStyle === 'offer' ? '293px' : '289px')};
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '434px')};
  }

  @media (max-width: 360px) {
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '280px')};
  }
`;

const ContentWrapper = styled.div`
  background: ${loyaltyTheme.contentBackground};
  background-size: cover;
  padding: 52px 41px 41px 41px;
  display: grid;
  grid-gap: 33px;
  text-align: center;

  @media (max-width: 1550px) {
    padding: 20px 20px 20px 20px;
    grid-gap: 20px;
  }
`;

const RewardWrapper = styled.div`
  display: grid;
  grid-gap: 7px;
`;

const RewardTitleText = styled(Body)`
  color: ${loyaltyTheme.rewardTextColor};
`;

const NextOfferText = styled(Title2)`
  color: ${loyaltyTheme.nextOfferColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FooterWrapper = styled.div`
  background: ${loyaltyTheme.footerBackground};
  padding: 18px 41px;
  display: grid;
  grid-gap: 5px;
  text-align: center;
  align-items: center;

  @media (max-width: 1550px) {
    padding: 15px 20px;
  }
`;

const YourBalance = styled(Body)`
  color: ${loyaltyTheme.yourBalanceColor};
`;

const Balance = styled(Title1)`
  color: ${loyaltyTheme.balanceColor};
`;

const Progress = styled.progress`
  width: 100%;
  height: 12px;
  border-radius: 0;
  border: 0;

  &::-moz-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
  }

  &::-webkit-progress-bar {
    border: 0;
    border-radius: ${props => (props.layoutStyle == 'menu' ? '0' : '5.5px')};
    background-color: ${loyaltyTheme.progressUnfillColor};
  }

  &::-webkit-progress-value {
    border: 0;
    border-top-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-bottom-left-radius: ${props =>
      props.layoutStyle == 'menu' ? '0' : '5.5px'};
    border-top-right-radius: 5.5px;
    border-bottom-right-radius: 5.5px;
    background-color: ${loyaltyTheme.progressFillColor};
  }

  @media (max-width: 1550px) {
    height: 10px;
  }
`;

const DelyseesLoyaltyCard = props => {
  return (
    <LayoutWrapper
      layoutStyle={props.layoutStyle}
      onClick={() => navigate('/offers')}
    >
      <ContentWrapper>
        <RewardWrapper>
          {props.nextOfferTitle ? (
            <>
              <RewardTitleText>{loyaltyTheme.rewardText}</RewardTitleText>
              <NextOfferText>{props.nextOfferTitle}</NextOfferText>{' '}
            </>
          ) : (
            <>
              <RewardTitleText>
                {loyaltyTheme.allRewardsUnlockedTitleText}
              </RewardTitleText>
              <NextOfferText>
                {loyaltyTheme.allRewardsUnlockedContentText}
              </NextOfferText>
            </>
          )}
        </RewardWrapper>
        <Progress max="100" value={props.nextOfferProgressValue} />
      </ContentWrapper>
      <FooterWrapper>
        <YourBalance>{loyaltyTheme.yourBalanceText}</YourBalance>
        <Balance>{`${props.balance} ${
          props.balance === 1
            ? loyaltyTheme.balanceText.singular
            : loyaltyTheme.balanceText.plural
        }`}</Balance>
      </FooterWrapper>
    </LayoutWrapper>
  );
};

export default DelyseesLoyaltyCard;
