import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import InternalLoyaltyCard from './components/InternalLoyaltyCard';
import {getLoyalty} from '../loyaltyActions';

const InternalLoyaltyCardContainer = ({loyalty, getLoyalty, ...props}) => {
  useEffect(() => {
    getLoyalty();
  }, []);

  if (!loyalty) return null;

  const {loyalty_details} = loyalty;

  const next_offer = loyalty_details.details.next_offer;

  const nextOfferProgress = next_offer ? next_offer.progress : null;
  const nextOfferTitle = next_offer ? next_offer.data.title : '';

  const balance = loyalty_details.details.current_points_balance;

  return (
    <InternalLoyaltyCard
      {...props}
      balance={balance}
      nextOfferProgress={nextOfferProgress}
      nextOfferTitle={nextOfferTitle}
      loyalty={loyalty}
    />
  );
};

const mapStateToProps = state => ({
  loyalty: state.loyaltyReducer.loyalty,
});

export default connect(mapStateToProps, {getLoyalty})(
  InternalLoyaltyCardContainer
);
