import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import appConfig from '../../../../../app-config';
import GenericLoyaltyCardBody from './GenericLoyaltyCard/GenericLoyaltyCardBody';
import InternalLoyaltyCardBodyB from './InternalLoyaltyCardB/InternalLoyaltyCardBodyB';
import InternalLoyaltyCardBodyA from './InternalLoyaltyCardA/InternalLoyaltyCardBodyA';
import SimpleLoyaltyCard from './SimpleLoyaltyCard/SimpleLoyaltyCard';
import TierBasedLoyaltyCard from './TierBasedLoyaltyCard/TierBasedCards/TierBasedLoyaltyCard';
import TierBasedLoyaltyCardVersion2 from './TierBasedLoyaltyCard/TierBasedCards/TierBasedLoyaltyCardVersion2';
import TierBasedLoyaltyCardVersion3 from './TierBasedLoyaltyCard/TierBasedCards/TierBasedLoyaltyCardVersion3';
import MevameLoyaltyCard from './ClientLoyaltyCards/MevameLoyaltyCard';
import DelyseesLoyaltyCard from './ClientLoyaltyCards/DelyseesLoyaltyCard';
import HatSixTravelPlazaLoyaltyCard from './ClientLoyaltyCards/HatSixTravelPlazaLoyaltyCard';
import MaestrosPizzaLoyaltyCard from './ClientLoyaltyCards/MaestrosPizzaLoyaltyCard';
import GroundBurgerLoyaltyCard from './ClientLoyaltyCards/GroundBurgerLoyaltyCard';
import ShinyTeaLoyaltyCard from './ClientLoyaltyCards/ShinyTeaLoyaltyCard';
import PinksBurgerLoyaltyCard from './ClientLoyaltyCards/PinksBurgerLoyaltyCard';
// import SorrentinosLoyaltyCard from './ClientLoyaltyCards/SorrentinosLoyaltyCard';
import StyleOne from './GenericLoyaltyCardStyles/StyleOne';
import StyleFour from './GenericLoyaltyCardStyles/StyleFour';
import StyleTwo from './GenericLoyaltyCardStyles/StyleTwo';
import StyleThree from './GenericLoyaltyCardStyles/StyleThree';
import StyleFourteen from './GenericLoyaltyCardStyles/StyleFourteen';

const GenericWrapper = styled.div`
  height: 289px;
  width: 434px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  background: ${props =>
    props.noImage
      ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent)'
      : '#fff'};
  display: grid;
  cursor: ${props => (props.layoutStyle === 'offer' ? 'default' : 'pointer')};
  grid-template-columns: 1fr;
  grid-template-rows: auto 73px;
  //margin: auto;
  float: left;

  &:hover {
    box-shadow: 0 2px 7px 0 rgba(66, 59, 112, 0.24);
    transition: all 0.25s ease;
  }

  @media (max-width: 360px) {
    overflow: inherit;
    width: ${props => (props.layoutStyle === 'offer' ? '250px' : '280px')};
  }
`;

const GenericFooter = styled.div`
  background: url(${props => props.theme.loyaltyCardFooterImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: ${props => props.theme.loyaltyCardFooterColor};
`;

const MenuWrapper = styled(GenericWrapper)`
  height: 209px;
  width: 314px;

  @media (min-width: 1551px) {
    height: 289px;
    width: 434px;
  }
`;

const MenuFooter = styled(GenericFooter)`
  background-color: ${props => props.theme.loyaltyBarValueColor};
  background-repeat: repeat;
`;

const OfferWrapper = styled(GenericWrapper)`
  height: 250px;
  width: 100%;
`;

const OfferFooter = styled(GenericFooter)`
  background-color: ${props =>
    props.theme.loyaltyCardFooterBackgroundColor
      ? props.theme.loyaltyCardFooterBackgroundColor
      : props.theme.loyaltyBarValueColor};
  background-repeat: repeat;
  background-size: auto;
`;

const InternalLoyaltyCard = props => {
  let Wrapper = GenericWrapper;
  let Footer = GenericFooter;

  const nextOfferProgressValue =
    props.nextOfferProgress !== null ? props.nextOfferProgress : 100;

  if ('menu' === props.layoutStyle) {
    Wrapper = MenuWrapper;
    Footer = MenuFooter;
  }

  if ('offer' === props.layoutStyle) {
    Wrapper = OfferWrapper;
    Footer = OfferFooter;
  }

  if (appConfig.loyaltyStyle === 1) {
    return (
      <StyleOne {...props} nextOfferProgressValue={nextOfferProgressValue} />
    );
  }
  if (appConfig.loyaltyStyle === 2) {
    return (
      <StyleTwo {...props} nextOfferProgressValue={nextOfferProgressValue} />
    );
  }
  if (appConfig.loyaltyStyle === 3) {
    return (
      <StyleThree {...props} nextOfferProgressValue={nextOfferProgressValue} />
    );
  }
  if (appConfig.loyaltyStyle === 4) {
    return (
      <StyleFour {...props} nextOfferProgressValue={nextOfferProgressValue} />
    );
  }
  if (appConfig.loyaltyStyle === 14) {
    return (
      <StyleFourteen
        {...props}
        nextOfferProgressValue={nextOfferProgressValue}
      />
    );
  }

  if (appConfig.loyaltyStyle === 'simple') {
    return <SimpleLoyaltyCard {...props} />;
  } else if (appConfig.loyaltyStyle === 'mevame') {
    return (
      <MevameLoyaltyCard
        {...props}
        nextOfferProgressValue={nextOfferProgressValue}
      />
    );
  }
  //Offers are not enabled for Sorrentinos for now
  
  //else if (appConfig.loyaltyStyle === 'sorrentinos') {
  //   return (
  //     <SorrentinosLoyaltyCard
  //       {...props}
  //       nextOfferProgressValue={nextOfferProgressValue}
  //     />
  //   );
  // } 
  else if (appConfig.loyaltyStyle === 'delysees') {
    return (
      <DelyseesLoyaltyCard
        {...props}
        nextOfferProgressValue={nextOfferProgressValue}
      />
    );
  } else if (appConfig.loyaltyStyle === 'hatsix') {
    return (
      <HatSixTravelPlazaLoyaltyCard
        {...props}
        nextOfferProgressValue={nextOfferProgressValue}
      />
    );
  } else if (appConfig.loyaltyStyle === 'maestros') {
    return (
      <MaestrosPizzaLoyaltyCard
        {...props}
        nextOfferProgressValue={nextOfferProgressValue}
      />
    );
  } else if (appConfig.loyaltyStyle === 'ground-burger') {
    return (
      <GroundBurgerLoyaltyCard
        {...props}
        nextOfferProgressValue={nextOfferProgressValue}
      />
    );
  } else if (appConfig.loyaltyStyle === 'shinytea') {
    return (
      <ShinyTeaLoyaltyCard
        {...props}
        nextOfferProgressValue={nextOfferProgressValue}
      />
    );
  } else if (appConfig.loyaltyStyle === 'pinks-burger') {
    return (
      <PinksBurgerLoyaltyCard
        nextOfferProgressValue={nextOfferProgressValue}
        {...props}
      />
    );
  }
  if (appConfig.loyaltyStyle === 'tier') {
    if (appConfig.loyaltyBarStyle === 'horizontal') {
      return <TierBasedLoyaltyCard {...props} />;
    } else if (appConfig.loyaltyBarStyle === 'picasso-horizontal') {
      return <TierBasedLoyaltyCardVersion3 {...props} />;
    } else {
      return <TierBasedLoyaltyCardVersion2 {...props} />;
    }
  }

  return (
    <Wrapper {...props}>
      {appConfig.loyaltyCard === 'generic' ? (
        <GenericLoyaltyCardBody
          {...props}
          nextOfferProgressValue={nextOfferProgressValue}
        />
      ) : appConfig.loyaltyBarStyle === 'horizontal' ? (
        <InternalLoyaltyCardBodyB
          {...props}
          nextOfferProgressValue={nextOfferProgressValue}
        />
      ) : (
        <InternalLoyaltyCardBodyA
          {...props}
          nextOfferProgressValue={nextOfferProgressValue}
        />
      )}
      {(appConfig.loyaltyBarStyle !== 'semicircle' ||
        appConfig.loyaltyBarStyle !== 'styleC') && <Footer />}
    </Wrapper>
  );
};

export default InternalLoyaltyCard;
