import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import {
  Body,
  Headline,
  Title1,
  Title2,
} from '../../../../../components/Typography';

const loyaltyTheme = appConfig.loyaltyTheme || {
  contentBackground: '',
  brandLogo: '',
  rewardText: '',
  rewardTextColor: '',
  nextOfferColor: '',
  footerBackground: '',
  yourBalanceText: '',
  yourBalanceColor: '',
  balanceText: {singular: '', plural: ''},
  balanceColor: '',
  allRewardsUnlockedTitleText: '',
  allRewardsUnlockedContentText: '',
  progressUnfillColor: '',
  progressFillColor: '',
};

const LayoutWrapper = styled.div`
  background: ${loyaltyTheme.contentBackground};
  background-size: cover;
  padding: 28px 14px 14px 14px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  display: grid;
  grid-gap: 15px;

  height: ${props => (props.layoutStyle === 'offer' ? '293px' : '209px')};
  width: ${props => (props.layoutStyle === 'offer' ? '100%' : '314px')};

  @media (min-width: 1551px) {
    height: ${props => (props.layoutStyle === 'offer' ? '293px' : '289px')};
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '434px')};
  }

  @media (max-width: 360px) {
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '280px')};
  }
`;

const BalanceAndLogoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 0 9.5px;
`;

const BalanceWrapper = styled.div`
  //   display: grid;
  //   grid-gap: 4px;
`;

const YourBalance = styled(Body)`
  color: ${loyaltyTheme.yourBalanceColor};
  margin-bottom: 7px;
`;

const Balance = styled(Title1)`
  color: ${loyaltyTheme.balanceColor};
  font-weight: 600;
`;

const Logo = styled.img`
  width: 71px;

  @media (max-width: 1550px) {
    width: ${props => (props.layoutStyle === 'menu' ? '45px' : '71px;')};
  }
`;

const RewardWrapper = styled.div`
  background: ${loyaltyTheme.footerBackground};
  background-size: cover;

  border-radius: 9px;
  padding: 15px 10px;

  display: grid;
  grid-gap: 10px;
  min-height: 100px;
`;

const RewardTitleText = styled(Body)`
  color: ${loyaltyTheme.rewardTextColor};
`;

const NextRewardOfferWrapper = styled.div`
  display: grid;
  grid-gap: 3px;
`;

const NextOfferText = styled(Title2)`
  color: ${loyaltyTheme.nextOfferColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Progress = styled.progress`
  width: 100%;
  height: 13px;
  border-radius: 0;
  border: 0;

  &::-moz-progress-bar {
    border: 0;
    border-radius: 5.5px;
  }

  &::-webkit-progress-bar {
    border: 0;
    border-radius: 5.5px;
    background-color: ${loyaltyTheme.progressUnfillColor};
  }

  &::-webkit-progress-value {
    border: 0;
    border-top-left-radius: 5.5px;
    border-bottom-left-radius: 5.5px;
    border-top-right-radius: 5.5px;
    border-bottom-right-radius: 5.5px;
    background-color: ${loyaltyTheme.progressFillColor};
  }

  @media (max-width: 1550px) {
    height: ${props => (props.layoutStyle === 'menu' ? '8px' : '13px')};
  }
`;

const StyleThree = props => {
  return (
    <LayoutWrapper
      layoutStyle={props.layoutStyle}
      onClick={() => navigate('/offers')}
    >
      <BalanceAndLogoWrapper>
        <BalanceWrapper>
          <YourBalance>{loyaltyTheme.yourBalanceText}</YourBalance>
          <Balance>
            {`${props.balance} ${
              props.balance === 1
                ? loyaltyTheme.balanceText.singular
                : loyaltyTheme.balanceText.plural
            }`}
          </Balance>
        </BalanceWrapper>
        <Logo src={loyaltyTheme.brandLogo} layoutStyle={props.layoutStyle} />
      </BalanceAndLogoWrapper>
      <RewardWrapper>
        {props.nextOfferTitle ? (
          <NextRewardOfferWrapper>
            <RewardTitleText>{loyaltyTheme.rewardText}</RewardTitleText>
            <NextOfferText>{props.nextOfferTitle}</NextOfferText>
          </NextRewardOfferWrapper>
        ) : (
          <NextRewardOfferWrapper>
            <RewardTitleText>
              {loyaltyTheme.allRewardsUnlockedTitleText}
            </RewardTitleText>
            <NextOfferText>
              {loyaltyTheme.allRewardsUnlockedContentText}
            </NextOfferText>
          </NextRewardOfferWrapper>
        )}
        <Progress
          max="100"
          value={props.nextOfferProgressValue}
          layoutStyle={props.layoutStyle}
        />
      </RewardWrapper>
    </LayoutWrapper>
  );
};

export default StyleThree;
