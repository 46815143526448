import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import appConfig from '../../../../../../app-config';
import InternalLoyaltyCardBalanceWrapper from '../InternalLoyaltyCardBalanceWrapper';
import {
  Body,
  Headline,
  Title1,
  Title2,
} from '../../../../../components/Typography';

const loyaltyTheme = appConfig.loyaltyTheme || {
  contentBackground: '',
  rewardText: '',
  rewardTextColor: '',
  nextOfferColor: '',
  yourBalanceText: '',
  yourBalanceColor: '',
  balanceText: {singular: '', plural: ''},
  balanceColor: '',
  allRewardsUnlockedTitleText: '',
  allRewardsUnlockedContentText: '',
  progressUnfillColor: '',
  progressFillColor: '',
  brandLogo: '',
};

const LayoutWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  display: grid;

  height: ${props => (props.layoutStyle === 'offer' ? '293px' : '209px')};
  width: ${props => (props.layoutStyle === 'offer' ? '100%' : '314px')};

  @media (min-width: 1551px) {
    height: ${props => (props.layoutStyle === 'offer' ? '293px' : '289px')};
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '434px')};
  }

  @media (max-width: 360px) {
    width: ${props => (props.layoutStyle === 'offer' ? '100%' : '280px')};
  }
`;

const ContentWrapper = styled.div`
  background: ${loyaltyTheme.contentBackground};
  background-image: url(${loyaltyTheme.contentBackgroundImage});
  background-size: cover;
  padding: 45px 0 30px 0;
  display: grid;
  grid-gap: 33px;

  @media (max-width: 1550px) {
    padding: ${props =>
      props.layoutStyle == 'menu' ? '20px 0 20px 0' : '45px 0 30px 0'};
    grid-gap: 20px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;

  @media (max-width: 1550px) {
    padding: ${props =>
      props.layoutStyle == 'menu' ? '0 20px 10px 20px' : '0 20px 30px 20px'};
  }
`;

const RewardWrapper = styled.div`
  display: grid;
  padding: 0 20px;

  @media (max-width: 1550px) {
    padding: 0 20px;
  }
`;

const RewardTitleText = styled(Body)`
  color: ${loyaltyTheme.rewardTextColor};
`;

const NextOfferText = styled(Title2)`
  color: ${loyaltyTheme.nextOfferColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BalanceWrapper = styled.div`
  display: grid;
`;

const YourBalance = styled(Body)`
  color: ${loyaltyTheme.yourBalanceColor};
`;

const Balance = styled(Title1)`
  color: ${loyaltyTheme.balanceColor};
`;

const Logo = styled.img`
  max-height: 71px;

  @media (max-width: 1550px) {
    max-height: ${props => (props.layoutStyle == 'menu' ? '45px' : '71px')};
  }
`;

const Progress = styled.progress`
  width: 101%;
  height: 17px;
  border-radius: 0;
  border: 0;

  &::-moz-progress-bar {
    border: 0;
  }

  &::-webkit-progress-bar {
    border: 0;
    background-color: ${loyaltyTheme.progressUnfillColor};
  }

  &::-webkit-progress-value {
    border: 0;
    border-top-right-radius: 5.5px;
    border-bottom-right-radius: 5.5px;
    background-color: ${loyaltyTheme.progressFillColor};
  }

  @media (max-width: 1550px) {
    height: ${props => (props.layoutStyle == 'menu' ? '10px' : '15px')};
  }
`;

const DelyseesLoyaltyCard = props => {
  return (
    <LayoutWrapper
      layoutStyle={props.layoutStyle}
      onClick={() => navigate('/offers')}
    >
      <ContentWrapper layoutStyle={props.layoutStyle}>
        <HeaderWrapper layoutStyle={props.layoutStyle}>
          <BalanceWrapper>
            <YourBalance>{loyaltyTheme.yourBalanceText}</YourBalance>
            <Balance>{`${props.balance} ${
              props.balance === 1
                ? loyaltyTheme.balanceText.singular
                : loyaltyTheme.balanceText.plural
            }`}</Balance>
          </BalanceWrapper>
          <Logo
            src={loyaltyTheme.brandLogo}
            width="auto"
            layoutStyle={props.layoutStyle}
          />
        </HeaderWrapper>
        <Progress
          max="100"
          value={props.nextOfferProgressValue}
          layoutStyle={props.layoutStyle}
        />
        <RewardWrapper>
          {props.nextOfferTitle ? (
            <>
              <RewardTitleText>{loyaltyTheme.rewardText}</RewardTitleText>
              <NextOfferText>{props.nextOfferTitle}</NextOfferText>{' '}
            </>
          ) : (
            <>
              <RewardTitleText>
                {loyaltyTheme.allRewardsUnlockedTitleText}
              </RewardTitleText>
              <NextOfferText>
                {loyaltyTheme.allRewardsUnlockedContentText}
              </NextOfferText>
            </>
          )}
        </RewardWrapper>
      </ContentWrapper>
    </LayoutWrapper>
  );
};

export default DelyseesLoyaltyCard;
