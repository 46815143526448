import appConfig from '../../../../../app-config';

export const loyaltyCardLayoutInfoObj = {
  semicircle: {
    contentWrapper: {
      gridRowLayout: '2fr auto 1fr',
      gridColumnLayout: '1fr 1fr 1fr',
      gridGap: '10px',
      gridColumnMobileLayout: '0px 1fr 0px',
      gridColumnMenuLayout: '0px 1fr 0px',
      gridGapMenu: '5px',
      height: 'inherit',
    },
    progressBarDiv: {
      gridColumn: '2',
      gridRow: '1',
      margin: '20px auto 0 auto',
      marginMenu: '10px auto 0 auto',
    },
    imageDiv: {
      gridColumn: '2',
      gridRow: '1',
      alignSelf: 'end',
      margin: '10px auto 0 auto',
    },
    image: {
      maxHeight: '70px',
    },
    nextDataDiv: {
      gridColumn: '2',
      gridRow: '2',
    },
    caption1: {
      fontWeight: '600',
      textAlign: 'center',
    },
    headline: {
      textAlign: 'center',
      fontSizeForMenu: '17px',
    },
    footer: {
      gridColumn: '1/4',
      gridRow: '3',
      background: null,
      borderRadius: '0 0 8px 8px',
      backgroundColor: 'rgb(228, 101, 24)',
    },
    balanceDiv: {
      gridColumn: '2',
      gridRow: '3',
      display: 'flex',
      margin: 'auto',
    },
    balanceText: {
      textAlign: 'center',
      textColor: appConfig.styleConfig.loyaltyBalanceTextColour || '#fff',
      fontSizeForMenu: '15px',
      string: 'Your Balance:',
    },
    pointsDiv: {
      fontSizeForMenu: '15px',
      fontSize: '19px',
      style: {
        color: appConfig.styleConfig.loyaltyBalanceTextColour || '#fff',
        marginLeft: '5px',
      },
    },
  },
  horizontal: {
    contentWrapper: {
      gridRowLayout: '1fr 1fr 30px 1fr',
      gridColumnLayout: '1fr 120px 1fr 1fr',
      gridGap: '10px',
      gridColumnMobileLayout: '0px 1fr 1fr 0px',
      gridColumnMenuLayout: '0px 100px 1fr 0px',
      //   gridGapMenu: '5px',
      height: 'inherit',
    },
    progressBarDiv: {
      gridColumn: '2/4',
      gridRow: '3',
    },
    imageDiv: {
      gridColumn: '2',
      gridRow: '1/3',
      alignSelf: 'end',
      margin: '10px auto 0 auto',
    },
    image: {
      maxHeight: '70px',
    },
    nextDataDiv: {
      gridColumn: '2 / span 2',
      gridRow: '4',
      marginLeft: '35px',
    },
    caption1: {
      fontWeight: '600',
      textAlign: 'left',
      color: '#fff',
    },
    headline: {
      textAlign: 'left',
      fontSizeForMenu: '17px',
      color: '#fff',
    },
    footer: {
      gridColumn: '1/4',
      gridRow: '3',
      borderRadius: '0 0 8px 8px',
      background: null,
      backgroundColor: '#43454b',
    },
    balanceDiv: {
      gridColumn: '2 / span 2',
      gridRow: '2',
      marginLeft: '35px',
    },
    balanceText: {
      textAlign: 'left',
      textColor: '#fff',
      fontSizeForMenu: '20px',
      fontSize: '20px',
      string: 'Your Balance:',
    },
    pointsDiv: {
      fontSizeForMenu: '30px',
      fontSize: '30px',
      style: {
        color: '#fff',
        marginLeft: '0px',
        textAlign: 'left',
      },
    },
  },
};
