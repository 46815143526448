import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import appConfig from "../../../../../../../app-config";

const Wrapper = styled.div`
  height: 289px;
  width: ${(props) => (props.fullWidth ? "100%" : "434px")};
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  background-image: url(${(props) => props.backgroundImage});
  background-color: ${(props) => props.backgroundColor};
  display: grid;
  cursor: ${(props) => (props.layoutStyle === "offer" ? "default" : "pointer")};
  grid-template-columns: 1fr 100px;
  grid-gap: 10px;

  &:hover {
    box-shadow: 0 2px 7px 0 rgba(66, 59, 112, 0.24);
    transition: all 0.25s ease;
  }
  @media (max-width: 1550px) {
    width: ${(props) => (props.fullWidth ? "100%" : "314px")};
    height: 209px;
  }
`;

const DetailsDiv = styled.div`
  margin: 40px -51px 0px 0px;
  text-align: right;
  display: grid;
  grid-template-columns: 1fr auto;
  color: ${(props) => props.textColor};
`;

const Logo = styled.div`
  margin-top: -10px;
`;

const Label = styled.div`
  margin-right: -30px;
`;

const LoyaltyLevelDiv = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => (props.nextTier ? "#22362b" : "#fff")};
  border-radius: 14.5px;
  padding: 4px 11px 4px 12px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: auto;
  margin-right: 70px;
  margin-top: 0;
`;

const BalanceText = styled.div`
  font-size: 14px;
  color: ${(props) => props.textColor};
`;

const BalanceSpan = styled.span`
  font-size: 28px;

  @media (max-width: 1200px) {
    font-size: 250%;
  }
`;

const ProgressBarBalanceText = styled.div`
  margin-top: 12px;
  margin-left: 20px;
  font-size: 19px;
  font-weight: 500;
  color: ${(props) => props.textColor};
`;

const PointsText = styled.span`
  font-size: 28px;

  @media (max-width: 1200px) {
    font-size: 250%;
  }
`;

const Progress = styled.progress`
  width: 100%;
  height: 12px;
  border: 0;
  &::-moz-progress-bar {
    border: 0;
  }

  &::-webkit-progress-bar {
    border: 0;
    background-color: ${(props) => props.progressBarBackgroundColor};
  }

  &::-webkit-progress-value {
    border: 0;
    background-color: ${(props) => props.progressBarColor};
  }

  @media (max-width: 1550px) {
    height: 10px;
  }
`;

const ProgressBarWrapper = styled.div`
  grid-row: 2;
  grid-column: 1 / span 2;
`;

const TierBasedLoyaltyCard = ({ layoutStyle, loyalty }) => {
  let { loyalty_details, tier_details } = loyalty;
  const balance = loyalty_details.details.current_points_balance;
  const { loyaltyTierDetails, loyaltyTierNameStyle } = appConfig;

  return (
    <Wrapper
      fullWidth={layoutStyle === "offer"}
      onClick={() => navigate("/offers")}
      backgroundImage={loyaltyTierDetails[tier_details.name].backgroundImage}
      backgroundColor={loyaltyTierDetails[tier_details.name].backgroundColor}
      layoutStyle={layoutStyle}
    >
      <div style={{ margin: "40px 20px 0px 20px" }}>
        <BalanceText
          id="LoyaltyCard-BalanceDetails"
          textColor={loyaltyTierDetails[tier_details.name].textColor}
        >
          Your Balance:
        </BalanceText>
        <BalanceText
          id="LoyaltyCard-BalanceDetails"
          textColor={loyaltyTierDetails[tier_details.name].textColor}
        >
          <BalanceSpan>{balance} </BalanceSpan>
          <PointsText id="LoyaltyCard-PointsText">
            {balance === 1 || balance == 0 ? "Point" : "Points"}
          </PointsText>
        </BalanceText>
      </div>
      <DetailsDiv textColor={loyaltyTierDetails[tier_details.name].textColor}>
        {loyaltyTierNameStyle === "round" ? (
          <LoyaltyLevelDiv
            backgroundColor={loyaltyTierDetails[tier_details.name].textColor}
            nextTier={tier_details.next_tier_name}
          >
            {tier_details.name}
          </LoyaltyLevelDiv>
        ) : (
          <>
            <Label id="LoyaltyCard-TierDetailLabel">{tier_details.name}</Label>
            <Logo>
              <img
                src={loyaltyTierDetails[tier_details.name].logo}
                width="initial"
              />
            </Logo>
          </>
        )}
      </DetailsDiv>
      {tier_details.next_tier_name && (
        <ProgressBarWrapper>
          <Progress
            max={tier_details.next_tier_points}
            value={balance}
            progressBarColor={
              loyaltyTierDetails[tier_details.name].progressBarColor
            }
            progressBarBackgroundColor={
              loyaltyTierDetails[tier_details.name].progressBarBackgroundColor
            }
          />
          <ProgressBarBalanceText
            textColor={loyaltyTierDetails[tier_details.name].textColor}
          >
            {tier_details.next_tier_points - balance}
            {tier_details.progress_to_next_tier == 1 ||
            tier_details.progress_to_next_tier == 0
              ? " Point "
              : " Points "}
            to {tier_details.next_tier_name}
          </ProgressBarBalanceText>
        </ProgressBarWrapper>
      )}
    </Wrapper>
  );
};

export default TierBasedLoyaltyCard;
