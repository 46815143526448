import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import InternalLoyaltyCardBalanceWrapper from '../InternalLoyaltyCardBalanceWrapper';
import appConfig from '../../../../../../app-config';

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr;
  grid-gap: 16px;
  // margin: auto;
  margin: 16px;
`;

const InternalLoyaltyCardBodyA = props => {
  return (
    <ContentWrapper onClick={() => navigate('/offers')}>
      <div style={{margin: 'auto'}}>
        <CircularProgressbarWithChildren
          value={props.nextOfferProgressValue}
          // counterClockwise={true}
          styles={{
            root: {
              // width: '133px',
              maxWidth: '133px',
            },
            path: {
              stroke: 'black',
              transform: 'rotate(50deg)',
              transformOrigin: 'center center',
            },
            trail: {
              transform: 'rotate(50deg)',
              transformOrigin: 'center center',
            },
          }}
        >
          <img
            src={appConfig.styleConfig.loyaltyCardImage}
            width="auto"
            // height="63"
            style={{maxHeight: '50px'}}
          />
        </CircularProgressbarWithChildren>
      </div>
      <InternalLoyaltyCardBalanceWrapper {...props} />
    </ContentWrapper>
  );
};

export default InternalLoyaltyCardBodyA;
