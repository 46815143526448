import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import appConfig from "../../../../../../app-config";

const Wrapper = styled.div`
  height: 289px;
  max-width: 600px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 16px 0 rgba(66, 59, 112, 0.32);
  padding: 24px 32px;
  background: ${(props) =>
    props.noImage
      ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent)"
      : props.theme.loyaltyCardBackgroundColour};
  display: grid;
  cursor: ${(props) => (props.layoutStyle === "offer" ? "default" : "pointer")};
  grid-template-rows: 60px 1fr;
  grid-gap: 10px;
  //margin: auto;
  float: left;

  &:hover {
    box-shadow: 0 2px 7px 0 rgba(66, 59, 112, 0.24);
    transition: all 0.25s ease;
  }

  @media (max-width: 1550px) {
    width: ${(props) => (props.fullWidth ? "100%" : "314px")};
    height: 209px;
  }
`;

const Logo = styled.img`
  object-fit: cover;
  max-height: ${(props) => (props.loyaltyImageWidth ? "none" : "100%")};
  max-width: ${(props) => (props.loyaltyImageWidth ? "100px" : "none")};
`;

const BalanceText = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => props.theme.loyaltyFontColour};
`;

const BalanceTitle = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.loyaltyFontColour};
`;

const BalanceSpan = styled.span`
  font-size: 250%;
`;

const PointsText = styled.span`
  font-size: inherit;

  @media (max-width: 1200px) {
    font-size: 250%;
  }
`;

const SimpleLoyaltyCard = ({ balance, layoutStyle }) => {
  return (
    <Wrapper
      fullWidth={layoutStyle === "offer"}
      onClick={() => navigate("/offers")}
      layoutStyle={layoutStyle}
    >
      <div>
        <Logo
          src={appConfig.styleConfig.loyaltyCardImage}
          loyaltyImageWidth={appConfig.styleConfig.loyaltyCardImageWidth}
        />
      </div>
      <div style={{ alignSelf: "end" }}>
        <BalanceTitle>Your Balance:</BalanceTitle>
        <BalanceText>
          <BalanceSpan>{balance} </BalanceSpan>
          {appConfig.appSource === "sodexo" ? (
            <PointsText>{balance === 1 ? "Bite" : "Bites"}</PointsText>
          ) : (
            <PointsText>{balance === 1 ? "point" : "points"}</PointsText>
          )}
        </BalanceText>
      </div>
    </Wrapper>
  );
};

export default SimpleLoyaltyCard;
